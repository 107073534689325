@import "variables";
@import "bootstrap/scss/bootstrap";


.loaderLoader {
    display: none !important;
}

/* Misc */
.content {
  font-size: $custom-font-size;
}
body {
    font-family: $primary-font;
    color: darkgrey;
    max-width: 100vw !important;
    overflow-x: hidden !important;
}

h1, h2, h3, span, p, a {
    font-family: $font-family;
}

span {
    font-family: $secondary-font;
} 

a.nav-link.hideOnDesktop {
    display: none;
}

.row {
    max-width: calc(90% - 2em);
    margin: auto 6em;
}
a {
    text-decoration: none !important;
    background-color: transparent;
    font-family:  $secondary-font;
    font-weight: 400;
    font-size: 1em;
    color: #0b9ba4;
}
.col-sm-6 a {
    color: grey;
}
.col-sm-6 a:hover {
    color: #0e86a3;
}
.col-sm-6 img {
    max-width: 100%;
}

/* No wrap spans are used for the 501(c)(3) designation among other things */
span.no-wrap {
    white-space: nowrap;
}

section.container {
    margin: 0;
    max-height: unset !important;
    min-width: calc(100vw - 1em) !important;
    height: fit-content !important;
    min-height: fit-content !important;
    height: -moz-fit-content;
    min-height: -moz-fit-content;
}
header.jumbotron {
    margin-bottom: 0;
    border-radius: 0;
    max-height: unset !important;
    height: fit-content !important;
    min-height: fit-content !important;
}
header {
    height: 100vh; 
    max-height: 800px;
    overflow: hidden;
}
section .row {
    min-height: 100%;
}
.col-sm-6 h1 {
    margin-top: 2em;
}
strong {
    font-weight: 700;
}
h1 {
    font-weight: 500 !important;
    font-size: 2.5em;
    padding-left: 0.25em;
    color: black;
}
p {
    padding: 0;
    padding-left: 0.5em;
    font-size: .9em;
    font-weight: 300;
    font-family:  $secondary-font;
    line-height: 1.9em;
    padding-right: .5em;
}
h3 {
    color: black;
    font-size: 1.5em;
    margin-right: 10em;
    width: 100%;
}
h4 {
    font-size: 1.5rem;
    margin-left: .32em;
    margin-top: 2em;
    // margin-right: 18em;
    width: 100%;
}

/* Colors */
strong.red {
    color: #ef444d;
}
strong.blue {
    color: #169ea6;
}


/*  Hover Nav Box  */ 
.hoverNavbox {
    width: 65%;
    position: absolute;
    right: 5em;
    background: $brand-teal;
    z-index: 1000;
    color: white;
    top: 4em;
}
.hoverNavbox li {
    list-style: none;
    text-align: left;
    margin-left: 0;
}
.hoverNavbox a {
    color: white !important;
    padding: 0;
}
.hoverNavbox h3 {
    font-size: 1.25em;
    text-transform: uppercase;
    margin-top: 1em;
    color: white;
}
.hoverNavbox ul {
    padding-left: 0;
}
.hoverNavbox .row {
    max-width: 100%;
    padding-left: 1.25em;
    padding-right: 0em;
    margin: 1em;
    padding-top: .5em;
    padding-bottom: .5em;
}
.hoverNavbox a:hover {
    // color: $brand-blue !important;
    text-decoration: underline !important;
    color: white !important;
}
.hoverNavbox a.nav-link {
    font-family: $default-font;
    text-transform: none;
    line-height: 1.5em;
    letter-spacing: .01em;
    font-weight: 300;
    font-size: .9em;
}
.hoverNavbox li.nav-item {
    padding-top: .9em;
}
li.nav-item.contactUsLink {
    padding: 0.5em;
}
.contactUsLink a {
    text-transform: uppercase;
    color: #ed0744;
    font-family: $primary-font;
    letter-spacing: .05em;
    font-weight: 500;
}

/* Hierarchy Handling */
.hoverNavbox a.nav-link.child::before {
    content: '-' !important;
    color: transparent;
    padding-left: 1em;
}

/* Account Hoverbox */
.account .col-sm-3, .account .col-sm-9 {
    margin: 0;
    min-height: 100%;
    position: relative;
    display: block;
    padding: 0.25em;
}
.account .row {
    margin: 0;
    padding: 0.35em;
    position: relative;
    padding-bottom: 0;
}
.hoverNavbox.account {
    background: #e6e6e6;
    width: 30%;
    padding-bottom: .4em;
    right: 10.6em;
}
.account img {
    width: 2em;
    position: absolute;
    height: auto;
    padding: 0.25em;
    float: left;
    top: -0.25em;
}
.account div#app {
    font-size: .35em;
    color: #4a1f1f;
    font-family: $primary-font;
    text-align: right;
}
input.emailInput {
    float: right;
    display: block;
    position: absolute;
    right: 5em;
    width: calc(100% - 3em);
    font-size: 2.25em;
    color: black;
    font-family:  $secondary-font;
    font-style: italic;
    padding-left: 0em;
    margin-top: 1.5px;
}
.account span {
    font-size: 3em;
    font-family: $primary-font;
    color: black;
    display: block;
    width: 110%;
    margin-left: -1.75em;
    text-align: left;
}
.account button {
    font-size: 2.45em;
    text-transform: uppercase;
    background: #ed0744;
    border: none;
    color: white;
    float: right;
    padding-left: 1em;
    padding-right: 1em;
    font-weight: 500;
}
img.accountLight {
    left: .55em;
    top: .5em;
    min-width: calc(100% - 3.5rem);
    height: auto;
}

/* Search */
li.nav-item.search {
    padding-right: 0.5em;
    padding-left: 0.5em;
    // border-left: lightgrey 1px solid;
}
.searchBox::-webkit-scrollbar, .searchResults::-webkit-scrollbar {
    display: none !important;
}
.searchBox {
    display: block;
    position: absolute;
    width: 50vw;
    max-width: 100%;
    float: right;
    right: 17em;
    background: white;
    top: 1.25em;
    z-index: 1000;
}
.searchBox input {
    width: 100%;
    border-radius: 0em;
    padding: 0.5em;
    font-family:  $secondary-font;
}
.searchResults {
    max-height: 50vh;
    overflow: scroll;
    height: 50vh;
    overflow-x: hidden;
    height: fit-content;
}
.searchResults span.title {
    display: block;
    text-transform: uppercase;
    color: black;
    font-family: $primary-font;
    font-weight: 500;
    margin-top: .25em;
    margin-bottom: 0em;
}
.searchResults span.summary {
    font-size: 0.75em;
    color: grey;
}
.searchResults img {
    width: auto;
    max-width: 20%;
    margin-top: 0.5em;
    min-width: 20%;
}
.searchResults .infoContainer {
    display: inline-block;
    max-width: 75%;
    padding-left: 1em;
}
.searchResults .row:hover {
    background: #add8e669;
}
.searchResults .row {
    max-width: 100%;
    margin: auto;
    padding: 0.5em;
}
.searchResults span.loading  {
    padding-left: 0.5em;
    margin-top: 1em;
    margin-bottom: 1em;
    display: block;
    text-align: center;
}
span.cancelSearchButton {
    display: none;
}

/* Lightbox */
.lightbox {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: #252424d9;
    left: 0;
    top: 0;
    z-index: 1000;
}

/* Lightbox shadow */
.navAutoCloseCover {
    width: 100vw;
    z-index: 999;
    height: 100vh;
    left: 0;
    top: 0;
    position: fixed;
    background: transparent;
}

/* Mobile Nav Toggle */
button.navbar-toggler.collapsed, button.navbar-toggler, navbar-toggler-icon {
    background-color: #47b7aa;
}

/* Jumbotron */
.tileHeader {
    background: url(/assets/img/ThankYouBG.jpg);
    background-size: cover;
}
.jumbotron .col-sm-6 {
    color: white;
}

/* Footer */
.col-md-3.bciLogo {
    padding-top: 2.4em;
}
.container-fluid.footer {
    background: #2b2b2b;
    color: #aeaeae;
    padding: 1em;
    padding-left: 3em;
    padding-right: 3em;
}
.footer .col-md-6, .footer .col-md-3 {
    padding: 2em;
}
.footer h4 {
    text-transform: uppercase;
    font-size: 1.25em;
    color: #848484;
    margin-right: 0em;
    width: auto;
    margin-top: 0em;
}
.col-md-6.bciNewsletter {
    padding-right: 0em;
}
.footer a {
    color: #848484;
}
.footer a:hover {
    color: #2fae9f;
}
.footer ul.navbar-nav.flex-row {
    padding-left: 0;
}

/* Footer MC */
.mc-field-group {
    margin-top: 10px;
}
.mc_signup_input {
    width: 75%;
    height: 40px;
    padding-left: 10px !important;
    box-shadow: none;
    border: none;
}
button.mc_signup {
    background: #ed0744 !important;
    color: white;
    border: none !important;
    text-transform: uppercase;
    padding-bottom: 0.5em;
    font-weight: 500;
    border-radius: 0;
    padding-right: 1em;
    padding-left: 1em;
    box-shadow: none;
    height: 100%;
    padding-top: 0.5em;
}
.mc_signup i {
    font-size: 20pt;
    color: #aeaeae;
}
button.mc_signup:hover {
    background: #1f98a37a;
}
.footer span {
    display: block;
    width: 100%;
    text-align: center;
    color: #848484;
}
.linkList {
    margin: auto;
}
.linkList a {
    display: inline-block;
    padding: 0.5em;
    text-decoration: underline !important;
}
.footer .col-md-3 img {
    max-width: 100%;
    margin-top: 1em;
}
.footer li.nav-item {
    font-size: 1.5em;
    padding-top: 1.1em;
}

/* Footer alignment issues */
.footer .row, .footer .col-md-6, .container-fluid.footer {
    max-height: unset !important;
    height: fit-content !important;
    min-height: fit-content !important;
}


/*Paths*/
.pathButtons h5 {
    color: white;
    position: absolute;
    top: calc( 50% - 0.5em);
    width: 100%;
    text-align: center;
    font-family: $primary-font;
    font-size: 1.3em;
}

/* Breadcrumbs */
section.breadcrumbs {
    padding-top: 0em;
    margin-left: -.9em;
}
section.breadcrumbs.bcCoursePath {
    margin-left: .1em;
}

/* Color Tags */
.hasColorTag::before {
    background: #3dba95;
    width: 150px;
    height: 1em;
    content: "_";
    color: #ff000000;
    display: inline-block;
    margin-bottom: -0.25em;
    margin-left: -0.5em;
    margin-right: 0.5em;
    vertical-align: sub;
}
.hasColorTag.teal::before {
    background: $brand-teal;
}
.hasColorTag.red::before {
    background: $brand-red;
}
.hasColorTag.purple::before {
    background: $brand-purple;
}
h1.hasColorTag.red {
    margin-left: -1em;
}

/* Section Color Tags */
section.hasSectionColorTag::before {
    background: #3dba95;
    width: 2em;
    height: 17em;
    content: "_";
    color: #ff000000;
    display: block;
    margin-bottom: -0.25em;
    margin-left: 2.5em;
    margin-right: 0.5em;
    vertical-align: sub;
    position: absolute;
}

section.hasSectionColorTag.red::before {
    background: $brand-red;
}
section.hasSectionColorTag.purple::before {
    background: $brand-purple;
}
section.hasSectionColorTag.teal::before {
    background: $brand-teal;
}

/* Upcoming Events Footer */
section.upcomingEventsFooter {
    margin-top: 2em;
    margin-bottom: 7em;
    position: relative;
}
.upcomingEventsFooter h1 {
    font-size: 2.5em;
    margin-left: 2.25em;
    padding-left: 0.15em;
	margin-left: 2.45em;
	margin-bottom: 0.5em;
}
.upcomingEventsFooter .eventTile {
    width: 23.28%;
    overflow: hidden;
    display: inline-block;
    margin: .5em;
}
.upcomingEventsFooter .eventTile img {
    width: 100%;
}
.upcomingEventsFooter .eventTile h1 {
    text-transform: uppercase;
    margin-left: 0;
    font-size: 1.25em;
    padding-left: 0;
    margin-bottom: 0;
}
.upcomingEventsFooter .eventTile span {
    font-size: 0.75em;
    text-transform: uppercase;
    color: $brand-green;
}

/* Tile Items */
.tileItem {
    width: 23.28%;
    overflow: hidden;
    display: inline-block;
    margin: .5em;
}
.tileItem img {
    width: 100%;
    height: auto;
    text-align: center;
    border-style: solid;
    border-width: 1px;
    border-color: #EFEFEF;
}
.tileItem h1 {
    text-transform: uppercase;
    margin-left: 0;
    font-size: 1.25em;
    padding-left: 0;
    margin-bottom: 0;
    padding-top: .5em;
    line-height: 1em;
}
.tileItem span {
    font-size: 0.8em;
    text-transform: uppercase;
    color: $brand-green;
    line-height: .4em;
    font-style: italic;
}

/* Pink Stripe Divider */
section.upPinkStripesDivider {
    height: 5em;
    background: url(/assets/img/pink-stripes-divider.png);
    margin-top: 2em;
    min-width: 120%;
    background-size: cover;
    margin-bottom: -3rem;
    margin-left: -2rem;
    min-width: 120%;
}
section.downPinkStripesDivider {
    height: 5em;
    background: url(/assets/img/pink-stripes-divider-bottom.png);
    margin-top: 2em;
    background-size: cover;
    margin-bottom: -3rem;
    margin-left: -2rem;
    min-width: 120%;
}

/* CourseTags */
span.courseTag {
    text-transform: uppercase;
    text-align: center;
    margin: auto;
    background: white;
    display: block;
    margin-top: -0.5em;
    color: white;
    font-size: .75em;
}
span.courseTag.developer {
    color: $brand-blue;
    border: $brand-blue;
    border-width: thin;
    border-style: solid;
} 
span.courseTag.professional {
    color: $brand-purple;
    border: $brand-purple;
    border-width: thin;
    border-style: solid;
} 
span.courseTag.beginner {
    color: $brand-green;
    border: $brand-green;
    border-width: thin;
    border-style: solid;
}

/* Homepage fix */
.onlineCourses span.courseTag {
    padding-left: 0.5em;
    padding-right: 0.5em;
}

/* Edit on Github */
.sourceDetails {
    color: transparent;
    float: right;
    position: fixed;
    bottom: 0.5em;
    right: 1em;
}
.sourceDetails a {
    color: transparent;
}
.sourceDetails:hover {
    color: #1ec4cf;
}

/* Social Footer */
section.social {
    background: url(/assets/img/Social_Banner.jpg);
    background-size: cover;
    background-position: center;
    text-align: center;
    padding-top: 4em;
    padding-bottom: 4em;
    margin-bottom: 0;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.social::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.social {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
iframe#twitter-widget-0 {
    background: #00000096;
    border-radius: 0.5em;
}
a.startCourseButton:hover {
    color: white !important;
    background-color: #0b9ba4 !important;
}
.pageHeaderImage .no-wrap {
    white-space: nowrap;
    font-size: .9em;
    font-weight: 200;
    font-family: $default-font;
    line-height: 1.9;
    letter-spacing: .1px;
    margin-top: 1em;
    max-width: 80%;
}

button:hover {
    background: #0B9BA4 !important;
}

a.donateNow:hover {
    background: #0B9BA4 !important;
    color: white !important;
}

a.callToActionButton:hover {
    background: #0B9BA4 !important;
}

a.buttonStyle:hover {
    background: #0B9BA4 !important;
}


/* Disable Account */
// li#accountToggle {
//     display: none !important;
// }
// div#accountDropdown {
//     display: none !important;
// }

/* Overrides */
.uppercase {
    text-transform: uppercase;
}
.mobileNavSidebar {
    display: none;
}
.mobileNavSidebar {
    display: none;
    position: absolute;
    height: calc(100vh - 5.05em);
    max-width: 250px;
    min-width: 250px;
    right: -1px;
    left: unset;
    background: #149ba4;
    z-index: 999;
    color: white;
    float: right;
    overflow-x: hidden;
    overflow-y: scroll;
}
.mobileNavSidebar li {
    list-style: none;
    text-align: left;
}
.mobileNavSidebar h3 {
    color: white;
    text-transform: uppercase;
    margin-left: 0.5em;
    margin-top: 0.25em;
}
.mobileNavSidebar a {
    color: white !important;
}
.mobileNavSidebar ul {
    margin-left: 0;
    padding: 0;
}
.mobileNavSidebar .master li {
    text-align: center;
    text-transform: uppercase;
    border: 1px white solid;
    padding: 0.5em;
    margin-left: 0.5em;
    margin-right: 0.5em;
    margin-bottom: 0.25em;
}
.mobileNavSidebar .master .donate {
    background: $brand-red;
    border: 1px $brand-red solid;
}
.mobileNavSidebar .master a {
    font-weight: 500;
}
.mobileNavSidebar .master i.fa.fa-search {
    color: white;
}

// login functionality
section.userLoginPrompt {
    margin-top: 1em;
    display: none;
}
.userLoginPrompt .row {
    border: 1px solid #ed0744;
    padding: 1em;
}
.userLoginPrompt a {
    background: #ed0744;
    padding: 1em 3em;
    text-transform: uppercase;
    color: white !important;
    font-weight: 600;
    float: left;
    margin-top: 1em;
    font-family: kanit, sans-serif;
    letter-spacing: .05em;
}
.userLoginPrompt h3 {
    text-transform: uppercase;
    margin-right: 0em;
}
.userLoginPrompt span {
    display: block;
    position: relative;
    width: 100%;
}
.userLoginPrompt a.noThanks {
    margin-left: 1rem;
    background: #149ba4;
}

.shown {
    display: block !important;
}

#app a {
    padding: .08em 1em;
    background: #0b9ba4;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 2.5em;
    float: right;
    margin-left: 0.5em;
    font-family: Kanit, sans-serif;
}

img.gravatar {
    border-radius: 50%;
    width: auto;
    display: block;
    position: absolute;
    top: -0.25rem;
    left: -0.25rem;
    max-height: 100%;
}

.loadingMessage {
    width: 100%;
    text-align: center;
    float: right;
    min-height: 3rem;
}

img#accountImg {
    height: 1.25em;
    border: 1px #3cb0a2 solid;
    border-radius: 50%;
    margin-top: -0.1em;
}
.account .col-sm-3 {
    position: relative;
}
.account img {
    position: relative;
    max-height: 80%;
}
/* Factoids */
section.factoid{
    max-width: 90vw;
    margin: 6.5em;
    display: block;
    margin-bottom: 5em;
    border-top: 1px #ed0744 solid;
    border-bottom: 1px #ed0744 solid;
    height: auto;
}
span.didYouKnow{
    transform: rotate(-90deg);
    padding: 0.5em 1.5em 0.5em 1.5em;
    background: #ed0744;
    max-height: 3.5em;
    color: white;
    font-weight: 700;
    left: 10vw;
    font-size: 1.2vw;
    margin-top: 1.85em;
    position: absolute;
    font-family: kanit;
    }
.mainFocus h1 {
    font-size: 13vw;
    margin-top: -0.45em;
    text-align: left;
    padding-left: 0;
    margin-bottom: 0em;
    font-weight: 700 !important;
}
.mainFocus span {
    font-size: 3vw;
    width: 100%;
    text-align: left;
    color: black;
    margin-top: -1em;
    position: relative;
    display: block;
    font-family: Kanit;
    font-weight: 700;
    letter-spacing: .5em;
    margin-left: .1em;
    line-height: 1em;
}    
.mainFocus a {
    margin-left: .2em;
    font-size: .75em;
    color: #0b9ba4;
    text-decoration: underline !important;
    letter-spacing: .15em;
}
.explanation span{
    font-size: 2vw;
    float: right;
    font-style: italic;
    font-weight: 400;
    margin-top: -1em;
}
.explanation a{
    font-size: .8vw;
    letter-spacing: .1em;
}
.factoid .row {
    margin-top: 6.25em;
    margin-bottom: 4.1em;
    margin-left: 8em;
    margin-right: 4.5em;
}
.mainFocus a:hover{
    color: black !important;
}
.col-sm-7.explanation{
    margin-left: 0vw;
    max-width: 30vw;
}
.col-sm-5.mainFocus{
    margin-left: 5.75vw;
}

img.accountLight.greyswirl.lazy.gravatar.loaded {
    max-height: 4rem;
}
