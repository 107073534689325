
// Mobile login 
#mobileApp h1 {
    font-size: 0.85em;
}

#mobileApp a, #mobileApp button {
    padding: 0.25em 0.5em 0.25em 0.5em !important;
    background: white;
    color: #149ba4 !important;
    font-weight: 500;
    text-transform: uppercase;
    border: none;
}

#mobileApp button {
    padding-top: 0.12rem !important;
    padding-bottom: 0.12rem !important;
    background: #ee4245;
    color: white !important;
}
#mobileApp input.emailInput {
    width: calc(100% - 2rem);
    right: unset;
    font-size: 1.15em;
}
// form.loginForm {
//     margin-bottom: 1em;
// }
.loginForm button {
    float: right;
    z-index: 9999 !important;
    display: block;
    position: relative;
    margin-top: 1px !important;
    border: 1px solid #ee4245 !important;
}
div#mobileApp {
    border-bottom: 1px solid white;
	margin-bottom: 1em;
	padding-bottom: 1em;
}

/* Bootstrap Tablet Trigger */ 
/* Bootstrap collapses the nav at 992px */
@media screen and (max-width: 992px) {

	div#accountDropdown {
		display: none !important;
	}
	input.emailInput {
		width: 100%;
		display: block;
		position: relative;
		float: right;
		right: 0;
		font-size: 1em;    
		margin-bottom: 0.25em;
	}
	.mobileLogin {
		display: block;
		position: relative;
	}
	#mobileApp span {
		display: block;
		width: 100%;
	}
	#mobileApp form {
		position: relative;
		overflow: hidden;
		height: fit-content !important;
	}
	button.logout {
		background: #ee4245;
		color: white;
		border: none;
	}

    .sourceDetails {
	display: none;
    }

	// Hide My Account Icon on Mobile
	li#accountToggle {
		display: none !important;
	}

	// Always show my account dropdown
	.hoverNavbox.account {
		display: block !important;
		margin-top: 0.5em;
		width: 100vw;
		margin-left: -1em;
	}
	.account .col-sm-3, .account .col-sm-9 {
		display: inline-block;
		width: fit-content;
	}

    	/* Nav (Mobile General) */
	.hoverNavbox {
        width: 100%;
        left: 0;
        top: auto;
        position: relative;
    }
    li.nav-item.ml-auto.search {
        width: calc(100% - 2em);
        border: 1px solid $brand-green;
		margin: 1em;
		margin-top: 0;
    }
    li.nav-item.ml-auto.navbox {
        width: calc(100% - 2em);
        border: 1px solid $brand-green;
        margin: 1em;
    }
    li.nav-item.ml-auto.navbox::before {
        content: "Full Menu";
        text-transform: uppercase;
        color: $brand-green;
        vertical-align: -webkit-baseline-middle;
		font-size: 1.15em;
		float: left;
		margin-left: calc(50% - 2em);
		margin-top: 0.25em;
    }
    ul.navbar-nav.ml-auto {
        margin-right: 0;
    }
    li.nav-item.contactUsLink, .contactUsLink a, li.donations, .donations a.donateNow {
        margin-left: 0.5em;
        margin-right: 0.5em;
        margin-top: 0.5em;
	}
	li.nav-item.donations {
		margin-top: -1em;
	}
	li#accountToggle {
	    text-align: center;
	    margin-left: 0em !important;
	    margin-top: 1.5em;
	}
	.hoverNavbox.account {
    		background: #e6e6e6;
    		width: 50%;
    		padding-bottom: .4em;
    		margin-left: 11em;
	}

    /* Search */
    .searchInterface {
        width: 100vw;
        height: 100vh;
        margin-top: 2em;
    }
	.searchInterface input {
		width: 100%;
		border-radius: 0em;
		padding: 0.5em;
		border-color: black;
		font-family: Roboto;
	}
    span.cancelSearchButton {
        font-size: 3em;
        right: 0.25em;
        padding: 0;
        top: -0.5em;
    }
    .searchResults img {
        width: 100%;
        height: auto;
        max-width: unset;
        max-height: unset;
    }
    .searchResults .infoContainer {
        max-width: unset;
        padding-left: 0;
    }
    div#searchResults {
        max-height: unset;
        height: -webkit-fill-available;
    }
	.searchInterface input {
		width: 100%;
		border-radius: 0em;
		padding: 0.5em;
		border-color: black;
		font-family: Roboto;
	}
	.searchResults span.title {
		display: block;
		text-transform: uppercase;
		color: black;
		font-family: Kanit;
		font-weight: 500;
	}	
	.searchResults span.title {
	    font-family: Kanit;
	    font-weight: 500;
	}
	div#searchLightbox {
		left: 0;
		width: 100vw;
		z-index: 1001;
		position: fixed;
		height: 100vh;
		top: 0;
	}
	li.nav-item.ml-auto.search {
		margin-bottom: -0.5em;
	}
	span.cancelSearchButton {
		display: block;
		position: absolute;
		right: 0.5em;
		top: 0.25em;
		font-size: 1.5em;
	}
	
}

/* Tablet */
@media screen and (max-width: 800px ) {

    /* Page Header */
    .pageHeaderImage .col-sm-6 {
        min-width: calc(100vw - 6em);
		margin: auto 2em;   
	}
	.col-sm-6 h1 {
		margin-top: 0.5em;
	}
	
	.pageHeaderImage p {
		max-width: 100%;
	}
	
	.pageHeaderImage .col-sm-6 h1 {
    		font-size: 2em;
	}
	.pageHeaderImage a.callToActionButton {
		padding: 1em 3em; 
	}
	section.pageHeaderImage {
	    margin-bottom: 3em;
	}

	/*Breadcrumbs*/
	section.breadcrumbs {
    		padding-top: 0em;
	}
	
	/* Row Margins */
		.row {
    		margin: auto 2em;
		max-width: calc(100% - 4em);
	}
	
	/*Sections*/
	h1{
		font-size: 2em;
	}
	.hasColorTag {
    		margin-left: -3.46em;
	}

    /* Upcoming Events Footer */
	section.upcomingEventsFooter {
    		margin: 0em;
    		margin-bottom: 7em;
    		padding: 0em;
	}
	.upcomingEventsFooter h1 {
    		margin-left: 1.08em;
    		font-size: 2em;
	}
	section.hasSectionColorTag::before{
	    margin-left: -.5em;
	    height: 15em;
	}
	.upcomingEventsFooter .eventTile h1 {
    		font-size: 1.25em;
    		font-size: 1.25em;
    		padding-top: .5em;
    		line-height: 1em;
		    margin-left: 0em;
	}
	.upcomingEventsFooter .tileItem h1 {
    		font-size: 1.25em;
    		font-size: 1.25em;
    		padding-top: .5em;
    		line-height: 1em;
		    margin-left: 0em;
	}
	.upcomingEventsFooter .eventTile span {
    	font-size: 0.8em;
   	 text-transform: uppercase;
   	 color: #0b9ba4;
  	  font-style: italic;
  	  line-height: .4em;
	}
	.upcomingEventsFooter .tileItem span {
    	font-size: 0.8em;
   	 text-transform: uppercase;
   	 color: #0b9ba4;
  	  font-style: italic;
  	  line-height: .4em;
	}
	.upcomingEventsFooter .eventTile {
    		width: calc(23% - 1em);
    		min-height: 12em;
    		height: fit-content;
    		margin-right: .5em;
	}
	.upcomingEventsFooter .tileItem {
    		width: calc(23% - 1em);
    		min-height: 12em;
    		height: fit-content;
    		margin-right: .5em;
	}
	.upcomingEventsFooter .row {
		width: 100%;
		margin: auto 2em;
		max-width: calc(100%);
	}
  	.tileItem {
    		width: calc(31.3% - 1em);
    		min-height: 12em;
    		height: fit-content;
	}
    .tileItem img {
        width: 100%;
        height: auto;
        text-align: center;
    }
	.tileItem h1 {
    		padding-left: 0em;
	}

    /* Mobile Footer */
    .footer .col-md-3 {
        min-width: calc(50% - 2em);
        display: inline-block;
        max-width: unset;
        margin: 1em;
    }
	.footer .col-md-3 img {
    		margin-top: .5em;
	}
    .footer .col-md-6 {
        min-width: 100%;
        display: block;
        max-width: unset;
        margin: 1em;
        text-align: center;
        padding-left: 0;
    }
	.footer li.nav-item {
		margin-left: 0;
		font-size: 1.5em;
		padding: 0.25em;
		padding-left: 0;
		padding-top: .5em;
	}
	h4.float-right {
    		float: none !important;
	}
    button.mc_signup {
        width: 100%;
        margin-bottom: 2em;
    }
    .mc_signup_input {
        width: 100%;
    }
    .linkList {
        text-align: center;
    }

	/* Nav Sidebar Adjustments */
	ul.navbar-nav.ml-auto.desktopNav {
		display: none;
	}
	.mobileNavSidebar {
	    top: calc(4.72em - 1px);
	    display: block;
	    padding: 2em;
	    max-width: 500px;
	}
	div#navbarResponsive {
		height: 0 !important;
	}
	a.nav-link.normal {
	    font-family: "Open Sans";
	    text-transform: none;
	    line-height: 1.7em;
	    letter-spacing: .01em;
	    font-weight: 300;
	    font-size: 1em;
	}
	a.nav-link.child {
	    font-family: "Open Sans";
	    text-transform: none;
	    line-height: 1.7em;
	    letter-spacing: .01em;
	    font-weight: 300;
	    font-size: 1em;
	    margin-left: 2em;
	}
	.mobileNavSidebar .master a {
	    font-weight: 500;
	    font-family: Kanit;
	    font-weight: 600;
	    letter-spacing: .05em;
	}
	/*Factoid*/
	section.factoid {
		margin: 2.5em;
		margin-bottom: 4.5em;
	}
	span.didYouKnow {
		left: -2em;
	}
	.mainFocus h1 {
		margin-top: -.45em;
		font-size: 8.5em;
	}
	.mainFocus span {
		font-size: 2em;
		line-height: 1.2em;
	}
	span.didYouKnow {
		left: -1.3em;
		font-size: 1em;
		margin-top: 1.2em;
	}
	.factoid .row {
		margin-top: 5.25em;
		margin-bottom: 3.5em;
	}
	.mainFocus a {
		margin-left: 2.44em;
	}
	.col-sm-5.mainFocus {
		margin-left: -7vw;
	}
	.col-sm-7.explanation {
		margin-left: 9vw;
		max-width: 35vw;
	}
	.explanation span {
		font-size: 2.55vw;
	}
} 

/* Mobile */
@media screen and (max-width: 600px) {
	/*Nav*/
	.navbar-brand img {
    		padding-left: 0em;
	}
	.hoverNavbox.account {
	    background: #e6e6e6;
	    width: 91%;
	    padding-bottom: .4em;
	    margin-left: 1em;
	}
	.search a, .navbox a {
		padding-bottom: 0.25em;
	}
	.account span {
	    font-size: 2.5em;
	    font-family: kanit;
	    color: black;
	    display: block;
	    width: 100%;
	    margin-left: 4em;
	    text-align: left;
	}
	input.emailInput {
	    float: right;
	    display: block;
	    position: absolute;
	    right: 5em;
	    width: calc(73% - 3em);
	    font-size: 2.25em;
	    color: black;
	    font-family: Roboto;
	    font-style: italic;
	    padding-left: 0em;
	}
	.hoverNavbox .col-sm-3 {
	    width: fit-content;
	}
	.account .col-sm-3, .account .col-sm-9 {
    		display: inline-block;
    		width: 100%;
		margin-top: .25em;
	}
	img.accountLight {
	    top: .4em;
	}
    
    /* Page Header */
	
	.pageHeaderImage .col-sm-6 {
        	min-width: calc(100vw - 2em);
		margin: auto 1em;   
    	}
	.pageHeaderImage .col-sm-6 h1 {
    		font-size: 2em;
	}
	.pageHeaderImage a.callToActionButton{
		float: left;
		text-align: center;
    		font-family: kanit;
    		font-weight: 600;
	}
	section.pageHeaderImage {
	    min-height: 25vh;
	}
	
	
	/*Sections*/
	h1{
		font-size: 1.75em;
	}
	
	/*Events*/
	section.events {
    		min-height: 850px;
    		margin-bottom: 0em;
	}
	
	/*Courses*/
	.coursesThumbnailContainer {
	    padding-bottom: 1em;
	}
	
	/*FAQ*/
	a.callToActionButton.faqCTA {
    		margin-left: .5em;
	}
	
	/* Row Margins */
	.row {
    		margin: auto 1em;
		max-width: calc(100% - 2em);
	}
	
    /* Section Color Tags */
    section.hasSectionColorTag.red::before {
        margin-left: -1.5em;
        height: 100vh;
    }

    /* Header color tags */
    .hasColorTag::before {
        margin-left: -3.5em;
    }

    /* Upcoming Events Footer */
    .upcomingEventsFooter .row {
    	width: 100%;
    	margin-left: 1em;
	}
    .upcomingEventsFooter .tileItem, .upcomingEventsFooter .eventTile {
        width: 88%;
        margin-bottom: 0em;
    }
	.upcomingEventsFooter h1 {
		margin-left: 0.5em;
		padding-left: 0.15em;
		margin-bottom: 0.5em;
		width: 10em;
	}
	.hasColorTag {
    		margin-left: -1.5em;
	}
	section.hasSectionColorTag.red::before {
    		height: 90vh;
		margin-left: -2.5em;
	}
	.tileItem h1 {
    		margin-left: -.2em;
	}

	
	
	/*Mobile Footer*/
	.footer li.nav-item {
    		font-size: 1.25em;
	}
	.footer .col-md-6, .footer .col-md-3 {
    		padding: 1em 2em;
	}
	.col-md-6.bciNewsletter {
    		padding: 1em 2em 1em 0em;
	}
	ul.navbar-nav.flex-row {
    		display: inline-flex;
	}
	
	.mobileNavSidebar {
		top: calc(5.05em - 1px);
		display: block;
	}
	
	/*NavSideBar*/
	.mobileNavSidebar {
	    max-width: 300px;
	}
	a.nav-link.normal {
	    line-height: 1.8em;
	    font-size: .95em;
	}
	a.nav-link.child {
	    line-height: 1.8em;
	    font-size: .95em;
	}
	.mobileNavSidebar h3 {
	    font-size: 1.35em;
	}

	/*Factoid*/
	span.didYouKnow {
		left: -7.1em;
		font-size: 1em;
		padding-left: 6em;
		padding-right: 6em;
		margin-top: 6.6em;
	}
	section.factoid {
		margin: 1.5em;
	}
	.col-sm-6.explanation {
		max-width: 100%;
		margin-top: 4em;
		margin-left: 2.75em;
	}
	.explanation span {
		font-size: 5.5vw;
		float: right;
		font-style: italic;
		font-weight: 200;
		margin-top: 1.4em;
		margin-left: -1.1em;
	}
	.explanation a {
		font-size: 3vw;
		letter-spacing: .1em;
		margin-left: -2em;
	}
	.factoid .row {
		margin-bottom: 4.1em;
		margin-left: 4em;
		margin-right: 0em;
	}
	.col-sm-7.explanation {
		margin-left: 0;
		max-width: 80vw;
	}
}


@media screen and (max-width: 330px) {
	h1 {
        font-size: 2em;
	}
	span.didYouKnow {
		left: -2.55em;
	}
	.col-sm-6.explanation {
		font-size: .56em;
		max-width: 100%;
		margin-top: 5em;
		text-align: center;
	}
}
