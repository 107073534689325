html, body {
    overflow-x:hidden;
}
section {
    padding-top: 4em;
}
section.oops {
    padding-top: 0em;
    margin-bottom: 6em;
}
.oops span {
    display: block;
    width: 100%;
    margin-bottom: 1em;
    margin-top: 1em;
}
.oops input {
    padding-left: 1em;
    width: 90%;
    border: 1px solid $brand-green;
    border-right: none;
    outline: none;
}
.oops a {
    color: $brand-green;
    border: 1px solid $brand-green;
    border-left: none;
    padding-right: 1em !important;
}

.oops button.searchButton {
    width: 30%;
    background: $brand-red;
    color: white;
    text-transform: uppercase;
    margin: auto;
    margin-top: 1em;
    margin-bottom: 2em;
    border: none;
    font-weight: 500;
    padding: 0.5em;
}
h1.hasColorTag.red {
    margin-left: -1.62em;
}


.FourOFourSearchResults {
    margin: 5em;
    margin-top: 0;
    height: 100%;
    margin-bottom: 0;
    padding-right: 3em;
    padding-left: 3em;
}

a.searchResultLink {
    border: none;
}

.searchResults img {
    width: 20%;
    height: 100%;
}
