$white:    #fff;
$red:     #dc3545;
$body-bg: $white;
$body-color: $white;
$custom-font-size: 50px;
$font-family: "Kanit";
$primary-font: "Kanit";
$secondary-font: "Roboto";
$default-font: "Open Sans";

/* Brand colors */
$brand-teal : #0B9BA4;
$brand-red : #ed0744;
$brand-purple: #A30071;
$brand-green: #0FA54C;
$brand-blue: #00648C;
$brand-dark-blue: #003449;
$brand-grey : #848484;
$brand-yellow : #FFBC00;
$brand-orange: #FC3F0D;
$brand-dark-grey: #2B2B2B;
$brand-light-teal: #05D3AB;
$brand-light-grey: #EFEFEF;
